$white: white;
$dark-nav-color: white;
$primary: #51a9dd;
$secondary: #0d1525;
$tertiary: #bd9e4c;



@media(max-width:768px) {
    .grid-rows-7 {
        grid-template-rows: repeat(7, minmax(0, 1fr))
    }
    .grid-rows-6 {
        grid-template-rows: repeat(6, minmax(0, 1fr))
    }
    .grid-rows-5 {
        grid-template-rows: repeat(5, minmax(0, 1fr))
    }
    .grid-rows-4 {
        grid-template-rows: repeat(4, minmax(0, 1fr))
    }
    .grid-rows-3 {
        grid-template-rows: repeat(3, minmax(0, 1fr))
    }
    .grid-rows-2 {
        grid-template-rows: repeat(2, minmax(0, 1fr))
    }
}

.highlight {
    color: $primary;
}

.articles-list {
 @media(min-width:768px) {
    display: "grid";
    &.count-5, &.count-4, &.count-3 {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
    &.count-2 {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
    &.count-6 {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
 }
 @media(min-width:1000px) {
    display: "grid";
    &.count-4 {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
    &.count-5 {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
    &.count-3 {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
    &.count-2 {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
    &.count-6 {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
 }
}

lite-youtube {
    iframe {
        width: 100%;
        height: 100%;
    }
}