@tailwind layer;
@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --foreground-rgb: 34, 34, 34;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 34,34,34;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
    
}

.lyt-visually-hidden {
  display: none;
}

@layer base {
  h1 {
    @apply text-2xl !important;
  }
  h2 {
    @apply text-2xl !important;
  }
  h3 {
    @apply text-2xl !important;
  }
  /* ... */
}

@layer base {
  ol {
    @apply list-decimal list-inside !important;
  }
  ul {
    @apply list-disc list-inside !important;
  }
}