.player-card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    @media (min-width: 720px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
    }
    .stats-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}

.small-player-card {
    .stats-container {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 0.5rem;
    }
}