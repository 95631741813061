.gray-section {
  width: 100%;
  background-color: $secondary;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-attachment: fixed;

  
  h4 {
    color: white;
  }
  h5 {
    margin-top: 1rem;
    margin-bottom: 0;
    color: black;

  }
  .teaser-card {
    a, h5{
      color: white;  
    }
  }
}

.hero-card {
  padding: 1rem;
  padding-top: 0;
  margin-bottom: 0;
  height: 100%;
  border: 1px solid black;
  .card-container {
    display: block;
  }
}

.hero-container {
  // @include xy-grid-container(100%,0);

}

.image-holder {
  position: relative;
  text-align: center;
  color: white;


  .bottom-left {
    position: absolute;
    bottom: 0;
    left: 0;    
    background-color: #0D0C35;
    color: white;
    text-transform: uppercase;
    padding: 0.5rem;
    font-size: 0.8rem;
    font-weight: 700;
    margin-bottom: 0;
    border-radius: 0 10px 0px;
  }
  .archive-hero-text {
    position: absolute;
    background-color: $primary;
    color: white;
    text-transform: uppercase;
    bottom: 0;
    left: 10px;
    padding: 0.5rem;
    
  }
  &.teaser {
    p {
      font-size: 0.5rem !important;
    }
    h5 {
      margin-top: 1rem;
    }
  }

}

.card-section {
  margin-top: 1rem;
  padding: 0.5rem;
}

.front-section {  
  // @include xy-grid-container();
  margin-top: 1rem;
  margin-bottom: 1rem;
  h4 {
    margin-bottom: 1rem;
    text-decoration: underline;
    
  }
}
.grid3-container {
  @media (max-sm) {
    //@include xy-grid();
    justify-content: center;
  }
  width: 100%;
  
  display: grid;
  border-top: 2px solid white;
  background-color: white;
  margin-bottom: 2rem;

  .article-card {    
    height: 100%; 
    a {
      color: black;  
      h3 , h4, span {
        font-weight: 500;
        @media (max-sm) {
          font-weight: 800;
        }
      }    
    }
    .image-holder {
      border-radius: 10px;
      width: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      padding-bottom: 50%;
      display: flex;      
      a {
        width: 100%;
        height: 100%;
      }
    }
  }
  @screen md {
    grid-template: [first] 1fr [second] 1fr [third] 1fr;
    grid-template-rows: auto;
    row-gap: 1rem;
    column-gap: 2rem;
    grid-template-areas: 
      "article-hero article-1"
      "article-hero article-2"
      "grid-ad grid-ad" 
  }
  @media(min-width:1000px) {
    row-gap: 2rem;
    column-gap: 4rem;
  }
  @media max-sm {
    .article-hero, .article-1, .article-2, .article-3, .article-4, .article-5 {
      //@include xy-cell(11); 
      margin-bottom: 2rem;

    }
    .article-hero {
      order: 1;
    }
    .article-1 {
      order: 2;
    }
    .article-2 {
      order: 3;
    }
    .article-3 {
      order: 5;
    }
    .article-4 {
      order: 6;
    }
    .article-5 {
      order: 7;
    }
    .grid-ad {
      order: 4;
    }
    
  }
  .article-hero {
    grid-area: article-hero;
    height: 100%;
    order: 1;    
    .article-card {
      height: 100%;  
      @screen md {
        .image-holder {
          height: 60%;
        }
      }
      @media(min-width:800px) {        
        .image-holder {
          height: 70%;
        }
      }
    }
  }
  @screen md {
    .article-1, .article-2 {
      .image-holder {
        padding-bottom: 30%;
      }
      
    }
  }
  .article-1 {
    order: 2;
    grid-area: article-1;
    
    
  }
  .article-1, .article-2 {
    
    @screen md {
      .article-card {
        display: flex;
        flex-direction: row;
        .bg-image {
          background-size: cover;
          height: 100%;
        }
        .article-content {
          padding-left: 1rem;
        }
      }
    }
  }
  .article-2 {
    order: 3;
    grid-area: article-2;
  }

  .grid-ad { 
    grid-area: grid-ad;
    width: 100%;
    padding: 1rem;
  } 
}

.grid2-container {
  @media (max-sm) {
    //@include xy-grid();
  }
  width: 100%;
  
  display: grid;
  border-top: 2px solid white;
  background-color: 'white';
  margin-bottom: 1rem;
  @screen md {
    grid-template-columns: [first] 1fr [second] 1fr;
    grid-template-areas: 
      "article-hero article-hero"
      "article-1 article-2"
      "article-3 article-4";
  }
  @media (lg) {
    grid-template-columns: [first] 1fr [second] 2fr [third] 1fr;
    grid-template-rows: [firstrow] 350px [secondrow] 350px;
    grid-template-areas: 
    "article-1 article-hero article-3"
    "article-2 article-hero article-4";
  }
  
  @media(min-width:1500px) {
    grid-template-rows: [firstrow] 450px [secondrow] 450px;
    margin-bottom: 2rem;
  }
  
    .article-card {
      padding: 0.5rem;
      width: 100%;
      height: 100%;
      
      a {
        color: black;
      }
      h4 {
        margin-top: 1rem;
        margin-bottom: 0;
        span {
          @media (max-sm) {
            font-weight: bold;
          }
        &:hover {
          background-image: linear-gradient(to top,transparent,transparent 0,$tertiary 0,$tertiary 7px,transparent 7px);
        }
        }
      }
      img {
        width: 100%;
      }
    }

    .article-1 {
      @media (max-sm) {
        //@include xy-cell(12);
        order: 2;
      }
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      grid-area: article-1;
      @media (lg) {
        margin-left: 1rem;
      }
      
    }
    .article-2 {
      @media (max-sm) {
        //@include xy-cell(12);
        order: 3;
      }
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      @media (max-md) {
        border-left: 1px solid grey;
      }
      @media (lg) {
        margin-left: 1rem;
      }

      grid-area: article-2;
      
    }
    .article-3 {
      @media (max-sm) {
        // @include xy-cell(12);
        order: 4;
      }
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      grid-area: article-3;

      @media (lg) {
        margin-right: 1rem;
      }
      
    }
    .article-4 {
      @media (max-sm) {
        // @include xy-cell(12);
      }
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      @media (max-md) {
        
        border-left: 1px solid grey;
      }
      @media (lg) {
        margin-right: 1rem;
      }
      grid-area: article-4;
      
    }
    .article-hero {
      @media(sm) {
        width: 100%;
        order: -1;
      }
      text-align: center;
      margin-top: 0.5rem;
      h4 { 
        font-size: 3rem;
        line-height: 1;
        
      }
      grid-area: article-hero;
    }
}

.teaser-card {
  /*
  background: #F8F8F8;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  */
  a {
    color: black;
  }
  img {
    width: 100%;     
    border-radius: 10px;
  }

  cursor: pointer;
  h4 {
    color: black;
  }
  h5 {
    margin-top: 1rem;
    margin-bottom: 0;
    color: black;
    span {
      &:hover {
      background-image: linear-gradient(to top,transparent,transparent 0,$tertiary 0,$tertiary 7px,transparent 7px);
    }  
    }
    
  }
}
.moneyball {
  a {
    img {
      width: 100%;
    }
  }
}

.podcast-section {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.podcast-container {
  background: transparent linear-gradient(284deg, #FFFFFF 0%, #F8F8F8 3%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #0000004D;
  border-radius: 10px;
  padding: 1rem 2rem;
  @screen md {
    padding: 2rem;
  }
  
  @media (lg) {
    padding: rem-calc(81) rem-calc(87);
  }
  
  .image-container {
    @media (max-sm) {
      margin-bottom: 1rem;
    }
    .podcast-image {
      display: block;
      @media (max-sm) {
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        padding-bottom: 50%;        
      }
      padding-bottom: 70%;
      width: 90%;
      @screen md {
        width: 100%;
        height: 100%;
        padding-bottom: 100%;
      }
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      
      margin: auto;
      height: 100%;
      background-position: center center;      
      
    }
    
  }
  .content-container {
    .title {
      font-weight: bold;
      display: block;
      margin-bottom: 1rem;
    }
  }
  .content, .listen-button {
    margin-bottom: 1rem;
  }

}

.listen-button {
  background: #F7DD72;
  box-shadow: 0px 3px 6px #00000040;
  border-radius: 10px;
  color: black;
}
.podcast-links {
  display: flex;
  margin-top: 2rem;
  @media (max-sm) {
    flex-direction: column;
    align-items: center;
    .apple-podcasts {
      margin-bottom: 1rem;
    }
  }
  
}
.pod-button {
  box-shadow: 0px 3px 6px #00000040;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  padding: 0.8rem 1.3rem;
  display: flex;
  color: black;
  width: 236px;
  justify-content: space-between;

  .podcast-image {
    height: 24px;
    width: 24px;
    background-size: cover;
    margin-left: 1rem;
  }
}
.spotify {
  @screen md {
    margin-left: 1rem;
  }
  
}
.tabs-content {
  border: none;
  .tabs-panel {
    padding: 0;
    margin-top: 1rem;
  }
  
  
}
.front-tabs {
  border: none;
  border-bottom: 3px solid rgba(#262626, 0.3);
  margin-bottom: 2rem;
  .tabs-title {    
    margin-bottom: -0.15rem;
    text-transform: uppercase;
    &.is-active {
      border-bottom: 3px solid black;
    }
    a {
      color: black;
      padding: 1rem 2rem;
      background: transparent !important;
      font-size: 1.5rem;
    }
  }
}


.all-archive-card {
  margin-bottom: 2rem;
  a {
    display: flex;
    justify-content: space-between;
    @media (max-sm) {
      flex-direction: column;
    }
    .image-holder {
      width: 100%;
      @media (max-sm) {
        .bg-image {
          padding-bottom: 75%;
        }
        
      }
      @screen md {
        width: 45%;
      }

      margin-bottom: 0;   
      .bottom-left {
        display: block;
        margin-bottom: 0;
        bottom: 0;
        border-radius: 0px 10px;
        background-color: #0D0C35;
      }   
    } 
    .bg-image {
      background-size: cover;
      height: 100%;
      width: 100%;
      border-radius: 10px;
      background-position: center center;
    }
    .content {
      width: 100%;
      @screen md {
        width: 50%;
      }
      
      text-align: left;
      h5 {
        font-weight: bold;
        
      }
    }     
  }
}

.new-card {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px #00000029;
  
  border-radius: 10px;  
}
.teaser-card {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .content {
    padding: 1rem;
  }
  &.new-card {
    @media (max-sm) {
      margin-bottom: 1rem;
    }
    a {
      .image-holder {
        img {
          border-radius: 10px;
        }
        
      }
    }
    
    color: black !important;
    .bottom-left {
      color: white;
    }
    h5 {
      color: black;
    }
    p {
      color: black;
    }
  }
}
.front-page {
  header {
    //@include xy-grid-container();
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}


.moneyball {
  overflow: hidden;
}


.small-center-grid {
  @media (max-sm) {
    justify-content: center;
  }
}

.adsense {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.top-picks-slider-section {
  margin-bottom: 2rem;
  .small-center-grid {
    margin-bottom: 2rem;
  }
  @media (max-sm) {
    .flickity-button {
       display: none;
    }
  }
  @screen md {
    .flickity-prev-next-button.next { right: -3rem;}
    .flickity-prev-next-button.previous { left: -3rem;}
  }
  .flickity-page-dots {
      display: none;
  }
  .top-pick {
    background-color: #696969;
    //height: 150px;
    @media (max-sm) {
      max-width: 75%;
      
    }
    @screen md {
      max-width: 43%;
    }
    width: 100%;
    margin-left: 1rem;
    padding: 1rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    .grid-x {
      width: 100%;
    }
    .image-container {
      display: flex;
      align-items: center;
    }
    img {
      border-radius: 15px;
      width: 100%;
    }
    .background-image {
      background-size: cover;

      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
    }
    .top-pick-content {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      .header { font-weight: bold;}
      .stats {
        p {
          margin-bottom: 0;
        }
        font-size: 12px;
      }
    }
  }
}

.media-cards-container {
  background-image: url(https://scplaybook.com.au/wp-content/themes/scpb_2022-11-28_12-49/dist/assets/images/bg2.png);
  background-color: #0d1525;
  background-attachment: fixed;
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 80px 80px;
    @media (min-width: 768px) {
      
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 100px;
    }
  }
  
}

$mediaCardWidth: 208px;
.media-card {
  width: 100%;
  height: 100%;
  // width: calc($mediaCardWidth * 0.66);
  // height: calc($mediaCardWidth * 0.66);
  // 
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  &.size-cover {
    background-size: cover;
  }
}

.bg-betr {
  background-color: #093AD4;
}

.flag-container {
  .flag-article {
    .image {
      border-radius: 0 !important;
    }
  }
}

.hero-article {
  margin-bottom: 1rem;
  position: relative;
  min-height: 600px;
  justify-content: center;
  align-items: center;
  .content-container {
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: auto;
    margin-bottom: calc(5%);
  }
  .bg-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .title-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .article-bg-gradient {
    background: linear-gradient(0deg, rgba(2, 0, 36, 0.75) 0%, rgba(0, 0, 0, 0.75) 33%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0) 100%);
  }
}

.wp-block-embed__wrapper {
  iframe {
    max-width: 100% !important;
  }
}