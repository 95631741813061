    .contributor-header {
    @media(max-width:768px) {
        
    }
}

.video-flag-card {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    
}

.top-video-card {
    width: 100%;
    height: 100%;
    position: relative;
    
    .image {
        width: 100%;
        height: 100%;
        background-size: cover;
        @media(min-width:768px) {
            background-size: cover;
        }
        background-position: center center;
        background-repeat: no-repeat;
    }
    .text-container {
        position: absolute;
        bottom: 0.5rem;

        z-index: 100;
        padding: 0.5rem;
    }
    i {
        border-radius: 50%;
        font-size: 3rem;
        position: absolute;
        top: calc(50% - 1.5rem);
        left: calc(50% - 1.5rem);
        z-index: 100;
    }
    
}

.video-card {
    @media(min-width: 960px) {
        width: calc(33% - 4px) !important;
    }
}

.basic-video-card {
    @media(max-width: 760px) {
        // height: 150px;
    }
    border-radius: 0;
    .video-card-header,.rounded-xl {
        border-radius: 0;
    }
    
    i {
        opacity: 0.9;
        border-radius: 50%;
        font-size: 3rem;
        position: absolute;
        top: calc(50% - 1.5rem);
        left: calc(50% - 1.5rem);
        z-index: 100;
    }
}