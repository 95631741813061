.spotify-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media(min-width:768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    gap: 0.5rem
}

.spotify-embed-container iframe {
    background-color: rgb(24, 128, 170);
}