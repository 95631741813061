.purchase-button {
    box-shadow: none;
}
.subchip {
    width: 8rem;
    height: 2.5rem;   
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    .absolute {
        position: relative !important;
        left: 0;
        top: 0;
        transform: none;
        translate: none;
        margin-bottom: auto;
        
        width: 3rem;
        height: 1.75rem;
        
    }
    img {
        max-width: unset;
        position: relative;
        width: 2rem;
        height: 100%;
        border-radius: 0 !important;
        object-fit: contain;
    }
    span {
        position: relative;
        margin-left: 0;
    }
}