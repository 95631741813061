.wp-block-table {
    overflow-x: auto
}

.wp-block-table table {
    border-collapse: collapse;
    width: 100%
}

.wp-block-table thead {
    border-bottom: 3px solid
}

.wp-block-table tfoot {
    border-top: 3px solid
}

.wp-block-table td,.wp-block-table th {
    border: 1px solid;
    padding: .5em
}

.wp-block-table .has-fixed-layout {
    table-layout: fixed;
    width: 100%
}

.wp-block-table .has-fixed-layout td,.wp-block-table .has-fixed-layout th {
    word-break: break-word
}

.wp-block-table.aligncenter,.wp-block-table.alignleft,.wp-block-table.alignright {
    display: table;
    width: auto
}

.wp-block-table.aligncenter td,.wp-block-table.aligncenter th,.wp-block-table.alignleft td,.wp-block-table.alignleft th,.wp-block-table.alignright td,.wp-block-table.alignright th {
    word-break: break-word
}

.wp-block-table .has-subtle-light-gray-background-color {
    background-color: #f3f4f5
}

.wp-block-table .has-subtle-pale-green-background-color {
    background-color: #e9fbe5
}

.wp-block-table .has-subtle-pale-blue-background-color {
    background-color: #e7f5fe
}

.wp-block-table .has-subtle-pale-pink-background-color {
    background-color: #fcf0ef
}

.wp-block-table.is-style-stripes {
    background-color: transparent;
    border-bottom: 1px solid #f0f0f0;
    border-collapse: inherit;
    border-spacing: 0
}

.wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
    background-color: #f0f0f0
}

.wp-block-table.is-style-stripes.has-subtle-light-gray-background-color tbody tr:nth-child(odd) {
    background-color: #f3f4f5
}

.wp-block-table.is-style-stripes.has-subtle-pale-green-background-color tbody tr:nth-child(odd) {
    background-color: #e9fbe5
}

.wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color tbody tr:nth-child(odd) {
    background-color: #e7f5fe
}

.wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color tbody tr:nth-child(odd) {
    background-color: #fcf0ef
}

.wp-block-table.is-style-stripes td,.wp-block-table.is-style-stripes th {
    border-color: transparent
}

.wp-block-table .has-border-color td,.wp-block-table .has-border-color th,.wp-block-table .has-border-color tr,.wp-block-table .has-border-color>* {
    border-color: inherit
}

.wp-block-table table[style*=border-top-color] tr:first-child,.wp-block-table table[style*=border-top-color] tr:first-child td,.wp-block-table table[style*=border-top-color] tr:first-child th,.wp-block-table table[style*=border-top-color]>*,.wp-block-table table[style*=border-top-color]>* td,.wp-block-table table[style*=border-top-color]>* th {
    border-top-color: inherit
}

.wp-block-table table[style*=border-top-color] tr:not(:first-child) {
    border-top-color: currentColor
}

.wp-block-table table[style*=border-right-color] td:last-child,.wp-block-table table[style*=border-right-color] th,.wp-block-table table[style*=border-right-color] tr,.wp-block-table table[style*=border-right-color]>* {
    border-right-color: inherit
}

.wp-block-table table[style*=border-bottom-color] tr:last-child,.wp-block-table table[style*=border-bottom-color] tr:last-child td,.wp-block-table table[style*=border-bottom-color] tr:last-child th,.wp-block-table table[style*=border-bottom-color]>*,.wp-block-table table[style*=border-bottom-color]>* td,.wp-block-table table[style*=border-bottom-color]>* th {
    border-bottom-color: inherit
}

.wp-block-table table[style*=border-bottom-color] tr:not(:last-child) {
    border-bottom-color: currentColor
}

.wp-block-table table[style*=border-left-color] td:first-child,.wp-block-table table[style*=border-left-color] th,.wp-block-table table[style*=border-left-color] tr,.wp-block-table table[style*=border-left-color]>* {
    border-left-color: inherit
}

.wp-block-table table[style*=border-style] td,.wp-block-table table[style*=border-style] th,.wp-block-table table[style*=border-style] tr,.wp-block-table table[style*=border-style]>* {
    border-style: inherit
}

.wp-block-table table[style*=border-width] td,.wp-block-table table[style*=border-width] th,.wp-block-table table[style*=border-width] tr,.wp-block-table table[style*=border-width]>* {
    border-style: inherit;
    border-width: inherit
}