.subcard-container {
    // margin-top: 5rem;
    display: flex;
    flex-direction: column;
    // height: 500px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 1rem;
    @media(min-width:1000px) {
        display: grid;
        // grid-template-areas: 'card-hero card-hero'
        //     'card-1 card-2';
        grid-template-columns: 2fr;
        grid-template-rows: 1fr;
    }

    .card-title {
        font-size: 2rem;
    }

    .price {
        font-size: 1.5rem;
    }

    .Premium {
        .card-title {
            font-size: 2.5rem;
        }

        .price {
            font-size: 2.5rem;
        }
    }
    .Premium {
        .image, .image-container{
            height: 300px !important;
            background-size: cover !important;
        }
        
        .description-container {
            row-gap: 1rem;
            
            p {
                font-size: 1.25rem;
            }
        }
    }
    @media(max-width:768px) {
    .AFL {
        grid-area: card-1;
        
    }
    

    .subcard-index-0 {
        grid-area: card-0;
    }
    .subcard-index-1 {
        grid-area: card-1;
    }
}
    .subcard {
        @media(min-width:768px) {
            height: 100%;
            margin-top: auto;
            &.hero {
                height: 100%
            }
        }
        .image {
            height: 300px;
        }
        &.Premium {
            .content-container {
                background-image: linear-gradient(transparent,transparent 8px,#fff 0),linear-gradient(90deg,#00c4cc,#51a9dd);
                padding-top: 1rem;
            }
        }

        &.AFL {
            .content-container {
                background-image: linear-gradient(transparent,transparent 8px,#fff 0),linear-gradient(90deg,#e21e31,#e21e31);
                padding-top: 1rem;
            }
        }
        &.NRL {
            .content-container {
                background-image: linear-gradient(transparent,transparent 8px,#fff 0),linear-gradient(90deg,#00cf5d,#00cf5d);
                padding-top: 1rem;
            }
        }
        

        .description {
            ul {
                font-size: 0.85rem;
                list-style: inside;
            }
            
        }
    }
    .hero {
        
        &.order-1 {
            .image-container {
                // height: 250px;
            }
        }
        
    }
    .image-container {
        height: 300px;
        .image {
            background-size: contain;
        background-position: bottom center;
        background-repeat: no-repeat;
        }

    }
    .subcard-icon {
        width: 50;
        height:50;
    }
}

.subscribeCardsContainer::before {
    content: " ";
    display: block;
    width: 100%;
    
    box-shadow: 0 8px 16px 0 rgba(0,0,0,.32),0 -38px 48px 32px #fff;
    // box-shadow: 0 -28px 6px 0px rgba(241,245,249,0.85), 0 -50px 13px 9px rgba(255,255,255,0.18);
    position: relative;
    @media(min-width:768px) {
        .content-container-inner {
            width: 46%;
        }
    }
}