.new-article-card {
    @media(max-width:768px) {
        grid-template-areas: 'text text text text image';
        grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
        grid-auto-rows: 1fr;
        width: 100%;
        height: 120px;
        .image-container {
            grid-area: image;
        }
        .text-container {
            grid-area: text;
        }
    }

    @media(min-width:768px) { 
        grid-template-rows: 2fr 1fr;
        grid-template-columns: 1fr !important;
        .text-container {
            min-height: 112px;
        }
    }
}

.top-posts-container {
    @media(max-width:768px) {
        grid-template-rows: 1fr 120px 120px 120px 120px;
        display: grid;
        grid-template-columns: 1fr;
        .sub {
            height: 100%;
            display: flex;
            width: 100%;
        }
    }
    .sub {
        height: 100%;    
        width: 100%;
        display: flex;
        .new-article-card {
            width: 100%;
            height: 100%;
        }
    }

}


.contributor-card {
    // width: 100%;
    .image {
        // min-width: 260px
    }
    
}

.card-icon {
    width: 71px;
        height: 91px;
    &.nrl {
        width: 71px;
        height: 91px;
    }

}