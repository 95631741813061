.byline-container1 {
	display: flex;
	margin-bottom: 1rem;
	//@include xy-grid();
	.byline {
		//@include xy-cell(4);
		display: flex;
		//width: 100%;
		img {
			width: 100px;
			height: auto;
			@media (md) {
				width: auto;
				height: 100px;	
			}
		}
		h5 {
			line-height: 1.1;
			margin-bottom: 0;
			color: black;
		}
		.text-content {
			color: black;
			display: block;
			width: 200px;
			text-align: left;
			margin-left: 1rem;
			margin-top: auto;
			margin-bottom: auto;
			p {
				margin-bottom: 0;
				color: black;
			}
		}
		
	}
}
.article-date {
	//@include xy-cell(2);
	display: flex;
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	white-space: nowrap;
	text-align: center;
	p {
		width:100%;
	}
	@media (md) {
		text-align: right;	
	}
	
}


.article-card1 {
	background-color: #F8F8F8;
	border: 1px solid #C1C1C1;
	//background: linear-gradient(#f8f8f8 0%, #b9b9b9 100%);
	//border: 2px solid #c1c1c1;
	//filter: drop-shadow(0px 3px 20px rgba(106, 106, 106, 0.83));
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 10px;
	padding: 1rem 22px;
	.image-holder {
		margin-bottom: 1.5rem;
		border: 1px;
		img {
			border-radius: 10px;
		}
	}
	h5 {
		font-weight: lighter;
		color: #000000DE;
		margin-bottom: 1rem;
		@media (md) {
			margin-bottom: 2rem;
		}
		
	}
	p {
		color: #000000DE;
		margin-bottom: 1rem;
	}

}

.bodycontent {
	display: flex;
	flex-direction: column;
	row-gap: 1rem;
}

.article-image {
	@media(min-width: 1000px) {
		min-height: max(65vh,300px);
	}
}

.article-comments-container {
	li, article {
		margin-bottom: 0.25rem;
	}
}

.ras-component-container {
	min-height: 500px;
	#ras-content {
		min-height: 500px;
	}
	iframe { 
		min-height: 500px;
	}
}