.front-page-container {
    //Flag container
    .container-num-0 {
        @media(max-width: 768px) {
            width: 100%;
            max-width: 100%;
        }
    }
    .container {
        // margin-top: 2rem;
    }
    .container-num-1, .container-num-0 {
        margin-top: 0;
    }
    .media-cards-container .container {
        margin-top: 0;
    }

    .full-width-container {
        max-width: unset;
    }
}

.flag-article {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
    &:hover {
        .image {
            scale: 1.05;   
        }
    }
    .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        transition: all .3s cubic-bezier(.215,.97,.99,1)
    }
    .text-container {
        z-index: 3;
    }
    &.flag-article-0 {
        h2 {
            font-size: 2rem !important;
        }
    }
}
.grow-image-parent {
    position: relative;
    overflow: hidden;
    &:hover {
        .grow-image {
            scale: 1.05;   
        }
    }
}
.grow-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    transition: all .3s cubic-bezier(.215,.97,.99,1)
}

$gradientWidth: 5%;
$gradientHeight: 5%;
$color1: rgba(0,0,0,1) 0%;
$color2: rgba(0,0,0,0.3) 60%;
$color3: rgba(255,255,255,0) 80%;
$color4: rgba(255,255,255,0) 100%;

.container-num-0 {
    .article-bg-gradient {
        // @media(min-width:768px) {
        //     &::after {
        //         //RHS
        //         background: linear-gradient(270deg, $color1,$color2, $color3, $color4);
        //         content: "";
        //         position: absolute;
        //         right: 0;    
        //         top: 0;
        //         width: $gradientWidth;
        //         height: 100%
        //     }
        //     &::before {
        //         //LHS
        //         background: linear-gradient(90deg, $color1,$color2, $color3, $color4);
        //         content: "";
        //         position: absolute;
        //         left: 0;    
        //         width: $gradientWidth;
        //         height: 100%
        //     }
        // }
        .top-gradient {
            position: relative;
            top: 0;
            width: 100%;
            height: 100%;
    
            // &::after {
            //     //TOP
            //     content: "";
            //     background: linear-gradient(-180deg, $color1,$color2, $color3, $color4);
            //     position: absolute;
            //     top: 0;
            //     width: 100%;
            //     height: 5%;
            //     @media(min-width: 768px) {
            //         height: $gradientWidth;
            //     }
                
            // }
            
            &::before {
                //Bottom
                content: "";
                position: absolute;
                bottom: 0;
                width: 100%;
                height: $gradientWidth;
                background: linear-gradient(0deg, $color1,$color2, $color3, $color4);
            }
        }
    }
}


.article-bg-gradient {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, $color1, $color2, $color3, $color4);
    // @media (max-width: 768px) {
    //     background: linear-gradient(0deg, rgba(2,0,36,0.75) 0%, rgba(0,0,0,0.75) 35%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    // }
    
    
}

.load-more-container {
    .new-article-card {
        @media (min-width: 768px) {
            .image-container {
                padding-bottom: 50% !important;
            }
        }
    }
}

.new-article-card {
    h6 {
        line-height: 1.25;
    }
}

.front-page-videos-container {
    .front-page-videos-grid {
        display: grid;
        grid-auto-rows: 0;
        overflow-y: hidden;
        @media (max-width: 1000px) {
            @media(max-width: 760px) {
                
                grid-template-areas: "video-index-0 video-index-0"
                "video-index-1 video-index-2";
                grid-template-rows: 200px 110px;
                .video-index-0 {
                    grid-column: span 2/span 2;
                }
                .video-index-3,.video-index-4,.video-index-5,.video-index-6 {
                    display: none;
                }
                 
            }
            @media(min-width: 760px) {
                grid-template-areas: "video-index-0 video-index-0"
                "video-index-1 video-index-2"
                "video-index-3 video-index-4";
                grid-template-rows: 1fr 1fr;
                .video-index-0 {
                    grid-area: video-index-0;
                }
                .video-index-1 {
                    grid-area: video-index-1;
                }
                .video-index-2 {
                    grid-area: video-index-2;
                }
                .video-index-3 {
                    grid-area: video-index-3;
                }
                .video-index-4 {
                    grid-area: video-index-4;
                }
            }
        }

        
        @media (min-width: 1000px) {
            grid-template-areas: "video-index-0 video-index-0 video-index-1 video-index-2 video-index-3"
                                "video-index-0 video-index-0 video-index-4 video-index-5 video-index-6";
            .video-index-0 {
                grid-area: video-index-0;
            }
            .video-index-1 {
                grid-area: video-index-1;
            }
            .video-index-2 {
                grid-area: video-index-2;
            }
            .video-index-3 {
                grid-area: video-index-3;
            }
            .video-index-4 {
                grid-area: video-index-4;
            }
            .video-index-5 {
                grid-area: video-index-5;
            }
            .video-index-6 {
                grid-area: video-index-6;
            }
            grid-template-rows: 1fr 1fr;
        }

        
        
        grid-template-rows: 1fr;
        gap: 1rem;
        

        .top-video-card {
            .image, .article-bg-gradient {
                border-radius: 12px 12px 12px 12px !important;
            }
        }
    }
    
    // width: 100%;
}

.bloke-videos-container {
    .play-button, .fa-circle-play {
        color: #ffad59;
        background-color: white;
    }
}